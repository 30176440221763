
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Menu from "./components/Menu"
import NoPageFound from './components/NoPageFound';
import './style.css';
import HomePage from './components/HomePage';
import globalData from './data/global'
const text = "hhhh";
function App() {
  
  return (
    <div>
        <BrowserRouter>
          <Routes>
          <Route path="/" element={<HomePage globalData={globalData}/>}/>
          <Route path="/menu" element={<Menu globalData={globalData}  text={text}/>}/>
          <Route path="/*" element={<NoPageFound/>}/>
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
