import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MQ from "../components/MQ";
import HaliIliya from "../components/HaliIliya";
import FrankCafe from "./FrankCafe";
import BackgroundMusic from "./BackgroundMusic";

const HomePage = ({ globalData }) => {
  const [allInfo, setallInfo] = useState([]);

  // Fetch both categories and items when the component mounts
  useEffect(() => {
    fetch(globalData.apiLink + "view.php?itemListOf=info")
      .then((response) => response.json())
      .then((allData) => {
        setallInfo(allData); // Set categories along with their items
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  return (
    <div>
      <div className="container">
        <FrankCafe />
        {/* <SetLanguage/> */}
        {allInfo.welcome}
        <br></br>
        <br></br>
        <Link to="/menu">
          <div className="openMenuButton">
            <button className="btn-64">
              <span>{allInfo.openMenu}</span>
            </button>
          </div>
        </Link>
        <br></br>
        <div className="backgroundMusicButtonHomePage">
          <BackgroundMusic />
        </div>
        {allInfo.advertisement1 && (
          <div className="alert alert-warning mt-5" role="alert">
            <h4 className="alert-heading">اعلان</h4>
            <p>{allInfo.advertisement1}</p>
            <hr />
            <p className="mb-0">مع التحيات الادارة</p>
          </div>
        )}
        <HaliIliya />
      </div>
      <MQ />
    </div>
  );
};

export default HomePage;
