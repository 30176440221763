import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MQ from "./MQ";
import HaliIliya from "../components/HaliIliya";
import BackgroundMusic from "./BackgroundMusic";
import SocialMedia from "./SocialMedia";
import Offers from "./Offers";
import GoToTop from "./GoToTop";
import CarouselFadeExample from "./carousel";
import { Icon } from "@iconify/react";

const Menu = ({ globalData }) => {
  const [categories, setCategories] = useState([]);
  const [allData, setAllData] = useState([]);
  const [alllData, setAlllData] = useState(allData);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);

  useEffect(() => {
    fetch(globalData.apiLink + "view.php?itemListOf=categoriesAndItems")
      .then((response) => response.json())
      .then((allData) => {
        setAlllData(allData);
        setAllData(allData);
        setLoading2(false)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    fetch(globalData.apiLink + "view.php?itemListOf=categoriesAndItems")
      .then((response) => response.json())
      .then((categories) => {
        setCategories(categories);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const chooseCategory = (catID) => {
    if (catID === "all") {
      setAlllData(allData);
    } else {
      const setCat = allData.filter((item) => item.id === catID);
      setAlllData(setCat);
    }
  };
  let checkPrice = (item, item2) => {
    if (item2 > 0) {
      return (
        <div>
          <div>🔥 {item2}</div>
          <div>
            <span>{item}</span>
          </div>
        </div>
      );
    } else {
      return item;
    }
  };
  let checkIfOffer = (item, item2) => {
    if (item2 > 0) {
      return "OfferPriceDiscount";
    } else {
      return "";
    }
  };

  return (
    <div>
      <div className="menuContainer">
        <div className="menuTopButtons">
          <Link to="/">
            <div>
              <Icon
                icon="solar:home-bold-duotone"
                width="1.7em"
                height="1.7em"
              />
            </div>
          </Link>
          <SocialMedia />
          <BackgroundMusic />
        </div>

        <div className="menuActions">
          <div onClick={() => chooseCategory("all")}>
            <div>
              <Icon icon="ph:list-duotone" />
            </div>
            <div>الكل</div>
          </div>

          {loading === false ? (
            loading === false &&
            categories.map((category, index) => {
              return (
                <div
                  key={category.id}
                  onClick={() => chooseCategory(category.id)}
                >
                  <div>
                    <Icon icon={category.icon} />
                  </div>
                  <div>{category.cat}</div>
                </div>
              );
            })
          ) : (
              <div>
                <div>
                  <Icon
                    icon="svg-spinners:6-dots-rotate"
                    width="1em"
                    height="1em"
                  />
                </div>
                <div>تحميل...</div>
              </div>
          )}
        </div>

        <Offers />

        {loading2 === false ? (
            loading2 === false &&
            alllData.map((category, Index) => (
          <div key={category.id}>
            <div className="categorySec">
              <div className="catIndex">{Index + 1}#</div>
              <div>
                <div className="ItemCat">{category.cat}</div>
                <div className="NofI">
                  {category.cat} - {category.item_count}
                </div>
              </div>
            </div>
            <div className="menusCarousel">
              <CarouselFadeExample images={category.image} />
            </div>

            {category.items && category.items.length > 0 ? (
              category.items.map((item) => (
                <div
                  className={"catItems " + checkIfOffer(item.price, item.disc)}
                  key={item.id}
                >
                  <div className="itemsNamesAndPrices">
                    <div>{item.item}</div>
                    <div>{checkPrice(item.price, item.disc)}</div>
                  </div>
                </div>
              ))
            ) : (
              <div className={"catItemsNoData "}>لا يوجد بيانات</div>
            )}
          </div>
        ))
      ) : (
        <div>
          <Icon icon="svg-spinners:pulse-3" width="3em" height="3em" />
        </div>
      )}
        <MQ />
        <HaliIliya />
        <GoToTop />
      </div>
    </div>
  );
};

export default Menu;
