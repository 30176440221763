const globalData = {
  //
  // uploadsLink:"https://frank-rest.cafe/manage/uploads/categoriesImages/",
  // uploadsLink:"http://localhost/react/frank-cafe/manage/uploads/categoriesImages/",
  // apiLink:"https://frank-rest.cafe/manage/api/",
  // apiLink:"http://localhost/react/frank-cafe/manage/api/",

  uploadsLink: "https://frank-rest.cafe/manage/uploads/categoriesImages/",
  apiLink: "https://frank-rest.cafe/manage/api/",
  // uploadsLink: "../manage/uploads/categoriesImages/",
  // apiLink: "../manage/api/",
  welcome: "اهلاً وسهلاً بكم في فرانك كافيه",

  todaysOffer: "عرض اليوم، لو تلحك لو ماتلحك! ",

  goHome: "الرئيسية",
  mq: "هذا الموقع لا يعمل في هذا الجهاز",
  item: "عناصر",
  oldPrice: "السعر القديم",
  pauseMusic: "🎹 ايقاف الموسيقي",
  playMusic: "🎹 تشغيل الموسيقي",
  followUsOnInsta: "تابعنا على الأنستجرام",
};

export default globalData;
