import React, { useEffect, useState } from "react";

import globalData from "../data/global";
const Offers = () => {
  const [allData, setAllData] = useState([]);
  const [allInfo, setallInfo] = useState([]);

  useEffect(() => {
    fetch(globalData.apiLink + "view.php?itemListOf=items")
      .then((response) => response.json())
      .then((allData) => {
        setAllData(allData); // Set categories along with their items
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Fetch both categories and items when the component mounts
  useEffect(() => {
    fetch(globalData.apiLink + "view.php?itemListOf=info")
      .then((response) => response.json())
      .then((allData) => {
        setallInfo(allData); // Set categories along with their items
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  return (
    <div>
      {allData
        .filter((items) => items.disc !== "0")
        .map((items, index) =>
          index === 0 ? (
            <div className="categorySecOffers" key={index}>
              <div className="so">
                <img src="images/siteImages/redBanner.png" alt="redBanner" />
              </div>
              <div>
                <div className="ItemCat">{allInfo.todaysOfferTitle}</div>
                <div className="ItemCat">{allInfo.todaysOfferMessage}</div>
              </div>
            </div>
          ) : null
        )}
      {allData
        .filter((item) => item.disc !== "0")
        .map((itemsa, indexa) => {
          return (
            <div className={"catItemsOffers"} key={indexa}>
              <div className="itemsNamesAndPricesOffers">
                <div>
                  <span>#{indexa + 1}</span> {itemsa.item} {" / "}{" "}
                  <span className={"categoryText"}>{itemsa.cat}</span>
                </div>
                <div>🔥 {itemsa.disc}</div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Offers;
